























































import UserApi from '@/services/UserApi'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit, Ref } from 'vue-property-decorator'
import RelationContactApi from '@/services/RelationContactApi'
import {
  isRequired,
  isLengthCheck,
  hasNumber,
  hasSpecialChars,
  IsLower,
  IsUpper
} from '../../functions/formRules'

@Component
export default class ChargePassword extends Vue {
  @Ref() readonly form!: any
  loadingSave = false
  orgPassword = ''
  newPassword = ''
  repeatPassword = ''
  email = ''
  pswdUpdated = 0
  errorMsg:any = []
  items= [
    {
      text: 'Home',
      disabled: false,
      href: 'breadcrumbs_dashboard'
    },
    {
      text: 'Dashboard',
      disabled: true,
      href: 'breadcrumbs_link_1'
    }
  ]

  rulesPassword= [
    isRequired('This field is required'),
    isLengthCheck('Needs at least 10 characters or more.'),
    hasNumber('Need at least one numeric character.'),
    hasSpecialChars('Need at least one special character.'),
    IsUpper('Need at least one uppercase character'),
    IsLower('Need at least one lowercase character')
  ]

  mounted () {
    this.fetchProfile()
  }

  async fetchProfile () {
    const res = await new RelationContactApi().myProfile() as any
    if (res.result.status === 'success') {
      this.email = res.data.Email
    }
  }

  get rules () {
    return {
      mustHave: (val: string) => {
        let error = false
        if (!this.HasUpperCase(val)) {
          error = true
        }
        if (!this.HasLowerCase(val)) {
          error = true
        }
        if (!this.HasNumeric(val)) {
          error = true
        }
        if (!this.HasSpecialChar(val)) {
          error = true
        }
        if (!this.Has8Chars(val)) {
          error = true
        }
        if (error) {
          return 'Password must contain atlease one capital and lowercase letter, one special character, one number and must be atleast 10 characters long'
        }
        return true
      },
      mustMatch: (val: string) => {
        if (val !== this.newPassword) {
          return 'Passwords must match'
        }
        return true
      },
      required: (val: string) => !!val || 'Required'
    }
  }

  async updatePassword () {
    if (this.form.validate()) {
      this.errorMsg = []
      try {
        this.loadingSave = true
        const dt = await new UserApi().changePassword({ oldPassword: this.orgPassword, newPassword: this.newPassword }) as any
        if (dt.data.wasChanged) {
          this.pswdUpdated = 1
        } else {
          this.pswdUpdated = 2
          this.errorMsg = dt.data.errors
          if (this.errorMsg.length <= 0) {
            this.errorMsg.push('Your current password was wrong.')
          }
        }
        this.loadingSave = false
      } catch (error) {
        this.pswdUpdated = 2
        // [bitbucket]
        this.errorMsg.push('An unexpected error has occured, please try again later or contact our servicedesk')
        this.loadingSave = false
      }
    }
  }

  HasUpperCase (pass: string) {
    return pass.match(/([A-Z])/g)
  }

  HasLowerCase (pass: string) {
    return pass.match(/([a-z])/g)
  }

  HasNumeric (pass: string) {
    return pass.match(/([0-9])/g)
  }

  HasSpecialChar (pass: string) {
    return pass.match(/[@#!$%^&*)(+=._-]/g)
  }

  Has8Chars (pass: string) {
    return pass.length >= 8
  }
}
